<template lang="pug">
.om-wizard-top
  .om-wizard-top-holder
    om-logo-svg
  .om-wizard-top-progress(:style="{ opacity: !showProgress ? 0 : 1 }")
    .om-wizard-top-progress-line(:style="progressWidth")
  .om-wizard-top-content(v-if="showBack")
    om-link(primary withIconLeft @click="$emit('backClick')") {{ $t('back') }}
    slot
</template>

<script>
  export default {
    props: {
      showBack: {
        type: Boolean,
        default: false,
      },
      showProgress: {
        type: Boolean,
        default: true,
      },
      percent: {
        type: Number,
        default: 1,
      },
    },

    computed: {
      progressWidth() {
        return {
          width: `${this.percent}%`,
        };
      },
    },
  };
</script>

<style lang="sass">

  .om-wizard-body
    position: relative
    z-index: 1
  .om-wizard-top
    &-holder
      display: flex
      align-items: center
      height: 4.5rem
      padding-left: 2rem
      padding-right: 2rem

    &-content
      display: flex
      align-items: flex-start
      padding-left: 2rem
      padding-right: 2rem

    &-progress
      height: 0.8rem
      width: 100%
      background: #F1F2F4

      &-line
        height: 100%
        width: 10%
        background: #ED5A29
        border-radius: 0px 0.5rem 0.5rem 0px
</style>
